import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'

import closeIcon from '../../images/close.svg'
import { getCalls } from '../../services/api/calls'

export function NotesPopup({ isOpen, onClose, callId }) {
  Modal.setAppElement('.layout-container')

  const [notes, setNotes] = useState('')

  useEffect(() => {
    if (!callId) return

    getCalls({ id: callId })
      .then(res => {
        setNotes(res.data[0].notes)
      })
      .catch(err => {
        console.error(err.response)
      })
  }, [callId])

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { zIndex: 999 },
        content: {
          width: '700px',
          margin: '0 auto',
          padding: '4rem 4rem 2rem 4rem',
          height: '530px',
        },
      }}
    >
      <div
        onClick={() => onClose()}
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '24px',
          backgroundImage: `url("${closeIcon}")`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '2rem',
          right: '2rem',
        }}
      />

      <h1
        style={{
          fontSize: '20px',
          margin: '1em auto',
          maxWidth: '400px',
          textAlign: 'center',
          fontWeight: 700,
          textTransform: 'uppercase',
        }}
      >
        Notas de llamada
      </h1>

      <div
        style={{
          margin: '0 auto',
          display: 'flex',
          flexWrap: 'wrap',
          fontSize: '18px',
        }}
      >
        {notes ? notes : '[Sin notas]'}
      </div>

      <div
        style={{
          display: 'flex',
          width: '100px',
          margin: '0 auto',
          justifyContent: 'space-between',
        }}
      >
        <button
          style={{
            color: 'white',
            width: '100px',
            display: 'block',
            backgroundColor: '#1D1D1B',
            borderColor: '#707070',
            fontSize: '18px',
            lineHeight: 1.8,
            padding: 0,
            fontWeight: 700,
          }}
          onClick={() => {
            if (onClose) onClose()
          }}
        >
          Cerrar
        </button>
      </div>
    </Modal>
  )
}
