import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Select from 'react-select'
import moment from 'moment'
import SyncLoader from 'react-spinners/SyncLoader'

import './filters.scss'

import useProblematics from '../hooks/problematics'
import { ProblematicSelect } from '../components/ProblematicSelect'
import { DiagnosticSelect } from '../components/DiagnosticSelect'
import { StartCallHeader } from '../components/StartCallHeader'
import { getDiagnostics } from '../services/api/get-diagnostics'
import { getUsers } from '../services/api/users'
import { createCall } from '../utilities/create-call'
import { getSessions, getSessionsTotal } from '../services/api/sessions'
import { Paginate } from '../components/Paginate'
import { toast } from 'react-toastify'
import { NotesPopup } from '../components/NotesPopup'
import { LoginForm } from '../components/LoginForm'

export default function FiltersPage () {
  // fases de una sesión
  const phases = [
    { value: '', label: 'Ninguna' },
    { value: 'seguimiento', label: 'Seguimiento' },
    { value: 'tratamiento', label: 'Tratamiento' },
    { value: 'evaluación', label: 'Evaluación' }
  ]

  // estados de una sesión
  const statuses = [
    { value: '', label: 'Todos' },
    { value: '1', label: 'Borrador' },
    { value: '0', label: 'Archivado' }
  ]

  const priorities = {
    1: 'Alta',
    2: 'Media',
    3: 'Baja'
  }

  const [problematics] = useProblematics([])
  const [diagnostics, setDiagnostics] = useState([])
  const [users, setUsers] = useState([])
  const [sessions, setSessions] = useState({ sessions: [] })
  const [loading, setLoading] = useState(false)

  // filtros individuales
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [identificationNumber, setIdentificationNumber] = useState('')
  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')
  const [problematic, setProblematic] = useState(null)
  const [diagnostic, setDiagnostic] = useState(null)
  const [phase, setPhase] = useState(null)
  const [status, setStatus] = useState(null)
  const [psychologist, setPsychologist] = useState(null)
  const [filters, setFilters] = useState({})
  const [openNotes, setOpenNotes] = useState(false)
  const [callId, setCallId] = useState()
  const [page, setPage] = useState(-1)
  const [total, setTotal] = useState(0)
  const perPage = 15

  // obtiene del backend los diagnósticos
  useEffect(() => {
    getDiagnostics()
      .then(res => setDiagnostics(res.data))
      .catch(err => console.error(err.response))
  }, [])

  // obtiene del backend los usuarios (psicólogas)
  useEffect(() => {
    // remitente/psicóloga (role_id=2)
    getUsers({ role_id: 2 })
      .then(res => {
        const users = res.data.map(user => {
          return {
            value: user.id,
            label: `${user.name} ${user.name2} ${user.lastname} ${user.lastname2}`
          }
        })

        users.unshift({ value: '', label: 'Vacío' })

        setUsers(users)
      })
      .catch(err => console.error(err.response))
  }, [])

  // obtiene las sesiones filtradas desde backend
  useEffect(() => {
    if (page === -1) return

    // se usa page como el detonante de la consulta para evitar
    // consultas dobles. Si page es 0, se toma como 1 en el backend.

    setLoading(true)
    getSessionsTotal(filters)
      .then(res => {
        setTotal(res.data)
        return res.data
      })
      .catch(err => {
        console.error(err.response)
        setSessions({ sessions: [] })
      })
      .then(total => {
        if (!total) return { data: { sessions: [] } }
        return getSessions(filters, page, perPage)
      })
      .then(res => setSessions(res.data))
      .catch(err => console.error(err.response))
      .finally(() => {
        setLoading(false)
      })
  }, [page])

  function pushFilters () {
    const filterFields = {
      patient_name: name,
      patient_lastname: lastname,
      document_number: identificationNumber,
      date1,
      date2,
      problematic_id: problematic,
      diagnostic_catalog_item_id: diagnostic,
      phase,
      phone,
      is_draft: status,
      author: psychologist
    }

    // filtros que se enviarán al backend
    const notEmptyFields = {}

    Object.keys(filterFields)
      .filter(key => filterFields[key])
      .forEach(key => {
        notEmptyFields[key] = filterFields[key]
      })

    if (Object.keys(notEmptyFields).length === 0) {
      toast.warning('Debes especificar un filtro')
      return
    }

    setFilters(notEmptyFields)
    setPage((page + 1) % 2)
  }

  function getAuthorName (author_id) {
    const find = sessions.authors.find(a => a.id === author_id)
    if (find) {
      return find.name
    }
    return '-'
  }

  function getPatientName (patient_id) {
    const find = sessions.patients.find(p => p.id === patient_id)
    if (find) {
      return `${find.name} ${find.lastname}`
    }
    return '-'
  }

  function getProblematicName (problematic_id) {
    const find = sessions.problematics.find(p => p.id === problematic_id)
    if (find) {
      return find.name
    }
    return '-'
  }

  function getDiagnosticName (session_id) {
    const find = sessions.diagnostics.find(d => d.session_id === session_id)
    if (find) {
      return find.name
    }
    return '-'
  }

  function getPhoneFromCall (call_id) {
    const find = sessions.calls.find(c => c.id === call_id)
    if (find) {
      return find.phone
    }
    return '-'
  }

  function getCharacterizationName (call_id) {
    const call = sessions.calls.find(c => c.id === call_id)
    if (!call) {
      return '-'
    }
    const characterization = sessions.characterizations.find(
      c => c.id === call.call_characterization_id
    )
    if (characterization) {
      return characterization.name
    }
    return '-'
  }

  return (
    <Layout className='page-filters'>
      <LoginForm />
      <div>
        <StartCallHeader />
      </div>

      <div
        style={{
          display: 'flex',
          backgroundColor: 'rgba(255,255,255, 0.4)',
          color: 'white'
        }}
      >
        <div className='filters-group'>
          <label>
            <div>Nombre</div>
            <input
              type='text'
              placeholder='NOMBRE PACIENTE'
              className='textfield'
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete='ño-completes'
            />
          </label>

          <label>
            <div>Apellido</div>
            <input
              type='text'
              placeholder='APELLIDO DEL PACIENTE'
              className='textfield'
              value={lastname}
              onChange={e => setLastname(e.target.value)}
              autoComplete='ño-completes'
            />
          </label>

          <label>
            <div>Teléfono</div>
            <input
              type='text'
              placeholder='TELÉFONO'
              className='textfield'
              value={phone}
              onChange={e => setPhone(e.target.value)}
              autoComplete='ño-completes'
            />
          </label>

          <label>
            <div>Cédula</div>
            <input
              type='text'
              placeholder='CÉDULA'
              className='textfield'
              value={identificationNumber}
              onChange={e => setIdentificationNumber(e.target.value)}
              autoComplete='ño-completes'
            />
          </label>

          <hr />

          <label>
            <div>Fecha de inicio</div>
            <input
              type='date'
              placeholder='YYYY-MM-DD'
              className='textfield'
              value={date1}
              onChange={e => setDate1(e.target.value)}
            />
          </label>

          <label>
            <div>Fecha final</div>
            <input
              type='date'
              placeholder='YYYY-MM-DD'
              className='textfield'
              value={date2}
              onChange={e => setDate2(e.target.value)}
            />
          </label>

          <hr />

          <label>
            <div>Nombre de la psicóloga</div>
            <div style={{ display: 'flex' }}>
              <Select
                options={users}
                onChange={option => setPsychologist(option.value)}
                value={users.find(item => {
                  return item.value === psychologist
                })}
                placeholder='Psicóloga'
                className='select'
                classNamePrefix='select'
              />
            </div>
          </label>

          <label>
            <div>Fase</div>
            <div style={{ display: 'flex' }}>
              <Select
                options={phases}
                onChange={option => setPhase(option.value)}
                value={phases.find(item => {
                  return item.value === phase
                })}
                placeholder='Fase'
                className='select'
                classNamePrefix='select'
              />
            </div>
          </label>

          <label>
            <div>Estado</div>
            <div style={{ display: 'flex' }}>
              <Select
                options={statuses}
                onChange={option => setStatus(option.value)}
                value={statuses.find(item => {
                  return item.value === status
                })}
                placeholder='Estado'
                className='select'
                classNamePrefix='select'
              />
            </div>
          </label>

          <label>
            <div>Problemática</div>
            <div style={{ display: 'flex' }}>
              <ProblematicSelect
                problematic={problematics}
                value={problematic}
                placeholder='Problemática'
                onChange={val => setProblematic(val)}
                canSelectAny
                emptyText='Ninguna'
              />
            </div>
          </label>

          <label>
            <div>Diagnóstico</div>
            <div style={{ display: 'flex' }}>
              <DiagnosticSelect
                diagnostics={diagnostics}
                value={diagnostic}
                placeholder='Diagnóstico'
                onChange={val => setDiagnostic(val)}
                emptyText='Ninguno'
              />
            </div>
          </label>

          <button
            style={{
              background: 'white',
              color: 'var(--color4)',
              padding: '0.25rem 1rem',
              margin: '1rem 0',
              fontWeight: 'bold'
            }}
            onClick={pushFilters}
          >
            BUSCAR
          </button>
        </div>

        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              padding: '1rem',
              border: '1px solid var(--color4)',
              margin: '0 1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              fontSize: '16px'
            }}
          >
            <Paginate
              total={total}
              page={page}
              perPage={perPage}
              onPageChange={selected => {
                setPage(selected)
              }}
            />
          </div>

          <div style={{ padding: '1rem' }}>
            {loading ? (
              <div style={{ margin: '30px auto 0 auto', width: '60px' }}>
                <SyncLoader color='#fff' loading={loading} />
              </div>
            ) : (
              <table className='table table-results'>
                <thead>
                  <tr style={{ textTransform: 'uppercase' }}>
                    <th>Fecha</th>
                    <th>Nombre</th>
                    <th>Teléfono</th>
                    <th>Diagnóstico</th>
                    <th>Psicóloga</th>
                    <th>Problemática</th>
                    <th>Caracterización</th>
                    <th>Prioridad</th>
                    <th>Estado</th>
                    <th>Admin</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.sessions.map(session => {
                    return (
                      <tr key={session.call_id}>
                        <td>
                          {session.id === 0 ? (
                            <span>{moment.utc(session.created_at).local().format('YYYY-MM-DD HH:mm:ss')}</span>
                          ) : (
                            <Link
                              to={`/session/?patient_id=${session.patient_id}&session_id=${session.id}`}
                              style={{ color: 'white', display: 'inline-block', border: '1px solid white', padding: '2px 4px' }}
                            >
                              {moment.utc(session.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                            </Link>
                          )}

                        </td>
                        <td>{getPatientName(session.patient_id)}</td>
                        <td>{getPhoneFromCall(session.call_id)}</td>
                        <td>{getDiagnosticName(session.id)}</td>
                        <td>{getAuthorName(session.author)}</td>
                        <td>{getProblematicName(session.problematic_id)}</td>
                        <td>{getCharacterizationName(session.call_id)}</td>
                        <td>{priorities[session.casic_priority]}</td>
                        <td>
                          {session.id === 0 ? null : (
                            <Link
                              style={{
                                color: 'white',
                                border: '1px solid white',
                                display: 'inline-block',
                                padding: '0 4px',
                                lineHeight: '1.5'
                              }}
                              to={`/session/?patient_id=${session.patient_id}&session_id=${session.id}`}
                            >
                              {session.is_draft ? 'Borrador' : 'Archivado'}
                            </Link>
                          )}
                        </td>
                        <td>
                          {session.id === 0 ? (
                            <button
                              className='btn btn-inline'
                              onClick={() => {
                                setCallId(session.call_id)
                                setOpenNotes(true)
                              }}
                            >
                            Notas
                            </button>
                          ) : (
                            <button
                              className='btn btn-inline'
                              onClick={() => {
                                const phone = getPhoneFromCall(session.call_id)
                                createCall(phone, session.patient_id)
                              }}
                            >
                            +sesión
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {openNotes ? (
        <NotesPopup
          isOpen={openNotes}
          onClose={() => setOpenNotes(false)}
          callId={callId}
        />
      ) : null}
    </Layout>
  )
}
